import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Accordion from 'react-bootstrap/Accordion';
import { Routes, Route, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import './App.css';

import sabanner from './img/banner/student_assistant.png'
import busbanner from './img/banner/busbanner.png'
import foodbanner from './img/banner/foodbanner.png'
import noimage from './img/banner/noimage.png'

function ProjectListPage() {

    let navigate = useNavigate()

    return (
        <div style={{overflowX:"hidden"}}>
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" sticky='top' data-bs-theme="dark">
                <Container>
                    <Navbar.Brand onClick={()=>{
                        navigate('/projects')
                    }}>전설의 문어 - 프로젝트</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                        </Nav>
                        <Nav>
                        <Nav.Link href='/'>MAIN</Nav.Link>
                        <Nav.Link href='/projects'>PROJECT</Nav.Link>
                        <Nav.Link href='/ltynamuwiki'>NAMUWIKI</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Helmet>
                <title>전설의 문어 - 프로젝트</title>
            </Helmet>
            <h3 style={{marginTop:"50px"}}>🔥 앱 프로젝트</h3>
            <div className="row">
                <div className='col-md-2' style={{margin:"20px"}}>
                    <Card style={{borderRadius:"20px" ,width: '18rem', overflow:"hidden"}} data-bs-theme="dark">
                        <Card.Img variant="top" src={sabanner} />
                        <Card.Body>
                            <Card.Title>학생비서</Card.Title>
                            <Card.Text>
                            초중고 학생들을 위한 전문 비서
                            </Card.Text>
                        </Card.Body>
                        <ListGroup className="list-group-flush" data-bs-theme="dark" style={{fontSize:"15px"}}>
                            <ListGroup.Item><img src="https://img.shields.io/badge/flutter-02569B?style=for-the-badge&logo=flutter&logoColor=#02569B"/></ListGroup.Item>
                            <ListGroup.Item>개발기간 : 2개월</ListGroup.Item>
                        </ListGroup>
                        <Card.Body>
                            <Button variant="outline-light" onClick={()=>{alert("해당 프로젝트 깃허브는 비공개로 확인이 불가합니다. 😥")}}><i class="fa-brands fa-github"></i>  Github</Button>{' '}
                            <Button variant="outline-light" href="https://han.gl/w6lic" target="_blank"><i class="fa-solid fa-download"></i>  Download</Button>{' '}
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-md-2' style={{margin:"20px"}}>
                    <Card style={{borderRadius:"20px" ,width: '18rem', overflow:"hidden"}} data-bs-theme="dark">
                        <Card.Img variant="top" src={busbanner} />
                        <Card.Body>
                            <Card.Title>버스타자, 버타</Card.Title>
                            <Card.Text>
                            경기도민을 위한 버스도착정보 서비스
                            </Card.Text>
                        </Card.Body>
                        <ListGroup className="list-group-flush" data-bs-theme="dark" style={{fontSize:"15px"}}>
                            <ListGroup.Item><img src="https://img.shields.io/badge/flutter-02569B?style=for-the-badge&logo=flutter&logoColor=#02569B"/></ListGroup.Item>
                            <ListGroup.Item>개발기간 : 2개월</ListGroup.Item>
                        </ListGroup>
                        <Card.Body>
                            <Button variant="outline-light" href='https://github.com/DURAM0830/Bus_App_Project' target="_blank"><i class="fa-brands fa-github"></i>  Github</Button>{' '}
                            <Button variant="outline-light" href="https://play.google.com/store/apps/details?id=com.legendaryoctopus.busapp&hl=ko" target="_blank"><i class="fa-solid fa-download"></i>  Download</Button>{' '}
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-md-2' style={{margin:"20px"}}>
                    <Card style={{borderRadius:"20px" ,width: '18rem', overflow:"hidden"}} data-bs-theme="dark">
                        <Card.Img variant="top" src={foodbanner} />
                        <Card.Body>
                            <Card.Title>안먹어</Card.Title>
                            <Card.Text>
                                안전한 먹거리 식품 조회 서비스
                            </Card.Text>
                        </Card.Body>
                        <ListGroup className="list-group-flush" data-bs-theme="dark" style={{fontSize:"15px"}}>
                            <ListGroup.Item><img src="https://img.shields.io/badge/flutter-02569B?style=for-the-badge&logo=flutter&logoColor=#02569B"/></ListGroup.Item>
                            <ListGroup.Item>개발기간 : 1주</ListGroup.Item>
                        </ListGroup>
                        <Card.Body>
                            <Button variant="outline-light" href='https://github.com/DURAM0830/food_history_app' target="_blank"><i class="fa-brands fa-github"></i>  Github</Button>{' '}
                            <Button variant="outline-light" href="https://play.google.com/store/apps/details?id=com.legendaryoctopus.food_history_app&hl=en_US" target="_blank"><i class="fa-solid fa-download"></i>  Download</Button>{' '}
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <h3 style={{marginTop:"50px"}}>🤔 기타 프로젝트</h3>
            <div className="row">
                <div className='col-md-2' style={{margin:"20px"}}>
                    <Card style={{borderRadius:"20px" ,width: '18rem', overflow:"hidden"}} data-bs-theme="dark">
                        <Card.Img variant="top" src={noimage} />
                        <Card.Body>
                            <Card.Title>자격사항확인 서비스</Card.Title>
                            <Card.Text>
                            파이썬 동적 크롤링 기반 서비스
                            </Card.Text>
                        </Card.Body>
                        <ListGroup className="list-group-flush" data-bs-theme="dark" style={{fontSize:"15px"}}>
                            <ListGroup.Item><img src="https://img.shields.io/badge/python-3776AB?style=for-the-badge&logo=python&logoColor=white"/> </ListGroup.Item>
                            <ListGroup.Item>개발기간 : 2일</ListGroup.Item>
                        </ListGroup>
                        <Card.Body>
                            <Button variant="outline-light" href='https://github.com/DURAM0830/Python_Dynamic_Crawl' target='_blank'><i class="fa-brands fa-github"></i>  Github</Button>{' '}
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-md-2' style={{margin:"20px"}}>
                    <Card style={{borderRadius:"20px" ,width: '18rem', overflow:"hidden"}} data-bs-theme="dark">
                        <Card.Img variant="top" src={noimage} />
                        <Card.Body>
                            <Card.Title>NFC 관련 프로젝트</Card.Title>
                            <Card.Text>
                            출입 인증, 잔류 시간 체크 시스템
                            </Card.Text>
                        </Card.Body>
                        <ListGroup className="list-group-flush" data-bs-theme="dark" style={{fontSize:"15px"}}>
                            <ListGroup.Item><img src="https://img.shields.io/badge/python-3776AB?style=for-the-badge&logo=python&logoColor=white"/></ListGroup.Item>
                            <ListGroup.Item>개발기간 : 1주</ListGroup.Item>
                        </ListGroup>
                        <Card.Body>
                            <Button variant="outline-light" href='https://github.com/DURAM0830/NFC_utilization_project' target="_blank"><i class="fa-brands fa-github"></i>  Github</Button>{' '}
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-md-2' style={{margin:"20px"}}>
                    <Card style={{borderRadius:"20px" ,width: '18rem', overflow:"hidden"}} data-bs-theme="dark">
                        <Card.Img variant="top" src={noimage} />
                        <Card.Body>
                            <Card.Title>화면 정보 제공 시스템</Card.Title>
                            <Card.Text>
                            고등학교 시절 했었던 단기 프로젝트
                            </Card.Text>
                        </Card.Body>
                        <ListGroup className="list-group-flush" data-bs-theme="dark" style={{fontSize:"15px"}}>
                            <ListGroup.Item><img src="https://img.shields.io/badge/python-3776AB?style=for-the-badge&logo=python&logoColor=white"/></ListGroup.Item>
                            <ListGroup.Item>개발기간 : 1개월 (유지보수기간 포함)</ListGroup.Item>
                        </ListGroup>
                        <Card.Body>
                            <Button variant="outline-light" href='https://github.com/DURAM0830/My-School-Guidance-Screen-Display-Project' target="_blank"><i class="fa-brands fa-github"></i>  Github</Button>{' '}
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-md-2' style={{margin:"20px"}}>
                    <Card style={{borderRadius:"20px" ,width: '18rem', overflow:"hidden"}} data-bs-theme="dark">
                        <Card.Img variant="top" src={noimage} />
                        <Card.Body>
                            <Card.Title>경기도 버스 정보 시스템</Card.Title>
                            <Card.Text>
                                경기 버스 API 활용 프로젝트
                            </Card.Text>
                        </Card.Body>
                        <ListGroup className="list-group-flush" data-bs-theme="dark" style={{fontSize:"15px"}}>
                            <ListGroup.Item><img src="https://img.shields.io/badge/python-3776AB?style=for-the-badge&logo=python&logoColor=white"/></ListGroup.Item>
                            <ListGroup.Item>개발기간 : 1일</ListGroup.Item>
                        </ListGroup>
                        <Card.Body>
                            <Button variant="outline-light" href='https://github.com/DURAM0830/Gyunggi-do-Bus-Info-System' target="_blank"><i class="fa-brands fa-github"></i>  Github</Button>{' '}
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-md-2' style={{margin:"20px"}}>
                    <Card style={{borderRadius:"20px" ,width: '18rem', overflow:"hidden"}} data-bs-theme="dark">
                        <Card.Img variant="top" src={noimage} />
                        <Card.Body>
                            <Card.Title>네이버 날씨 크롤링</Card.Title>
                            <Card.Text>
                                파이썬 웹 크롤링 연습
                            </Card.Text>
                        </Card.Body>
                        <ListGroup className="list-group-flush" data-bs-theme="dark" style={{fontSize:"15px"}}>
                            <ListGroup.Item><img src="https://img.shields.io/badge/python-3776AB?style=for-the-badge&logo=python&logoColor=white"/></ListGroup.Item>
                            <ListGroup.Item>개발기간 : 1일</ListGroup.Item>
                        </ListGroup>
                        <Card.Body>
                            <Button variant="outline-light" href='https://github.com/DURAM0830/NaverWeather_with_Python' target="_blank"><i class="fa-brands fa-github"></i>  Github</Button>{' '}
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <div style={{padding:"50px"}}>
                <Accordion defaultActiveKey="0" data-bs-theme="dark">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>📢 기술 스택 관련</Accordion.Header>
                        <Accordion.Body>
                            사용한 기술 스택 기재는 대표 기술만 기재되어 있습니다. 부가적으로 사용한 기술 스택은 Github에서 확인 가능합니다.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>🤔 개인 프로젝트 여부</Accordion.Header>
                        <Accordion.Body>
                            현재 개인프로젝트 여부인지 확인이 불가합니다. 이는 추후 업데이트를 통해 개선하겠습니다.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <footer>
                <div className="footer">
                    <p>Copyright ⓒ 2024. 이원희 All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
}

export default ProjectListPage;
import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import markdownContent from './content.md'; // 마크다운 파일 경로
import rehypeRaw from 'rehype-raw';  // 상단에 import 추가
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Helmet } from 'react-helmet';

import './namuwiki.css'; // 스타일은 추가 정의 필요

function SupComponent({ children }) {
    const [tooltipContent, setTooltipContent] = React.useState('');

    const handleMouseEnter = () => {
        let href = null;
        if (Array.isArray(children)) {
            href = children[0]?.props?.href;
        } else if (children?.props?.href) {
            href = children.props.href;
        }

        if (href) {
            const footnoteId = href.match(/fn-?(\d+)/)?.[1];
            
            if (footnoteId) {
                const possibleIds = [
                    `user-content-fn-${footnoteId}`,
                    `fn-${footnoteId}`,
                    `fn${footnoteId}`
                ];
                
                let footnoteElement = null;
                for (const id of possibleIds) {
                    footnoteElement = document.getElementById(id);
                    if (footnoteElement) break;
                }
                
                if (footnoteElement) {
                    // 각주 내용 정제
                    let content = footnoteElement.innerHTML;
                    
                    // "↩" 링크가 포함된 a 태그 전체를 제거
                    content = content.replace(/<a[^>]*>↩<\/a>/g, '');
                    
                    // HTML을 텍스트로 변환하고 불필요한 문자 제거
                    const tempDiv = document.createElement('div');
                    tempDiv.innerHTML = content;
                    content = tempDiv.textContent
                        .replace(/↩\d+/g, '') // ↩숫자 패턴 제거
                        .replace(/↩/g, '') // 남은 ↩ 제거
                        .replace(/[^가-힣0-9\s.,!?()'"]+/g, '') // 한글, 숫자, 공백, 기본 문장부호만 남김
                        .replace(/^\s*[.,!?()'"]\s*/g, '') // 문장 시작의 문장부호 제거
                        .replace(/\s+/g, ' ') // 연속된 공백을 하나로
                        .trim();
                    
                    setTooltipContent(content);
                }
            }
        }
    };

    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id={`tooltip-${Math.random()}`}>
                    {tooltipContent || '로딩 중...'}
                </Tooltip>
            }
        >
            <sup
                onMouseEnter={handleMouseEnter}
                style={{ cursor: 'help' }}
            >
                {children}
            </sup>
        </OverlayTrigger>
    );
}

function NamuWikiPage() {
    const navigate = useNavigate();
    const [content, setContent] = React.useState('');

    React.useEffect(() => {
        fetch(markdownContent)
            .then((response) => response.text())
            .then((text) => setContent(text));
    }, []);

    return (
        <div>
            <Helmet>
                <title>누군가의 나무위키</title>
            </Helmet>
            {/* 네비게이션 바 */}
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" sticky="top" data-bs-theme="dark">
                <Container>
                    <Navbar.Brand
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/ltynamuwiki')}
                    >
                    누군가의 나무위키
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto" />
                        <Nav>
                            <Nav.Link onClick={() => navigate('/')}>MAIN</Nav.Link>
                            <Nav.Link onClick={() => navigate('/projects')}>PROJECT</Nav.Link>
                            <Nav.Link onClick={() => navigate('/ltynamuwiki')}>NAMUWIKI</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* 콘텐츠 섹션 */}
            <div className="content-container" style={{ padding: '20px' }}>
                <ReactMarkdown
                    children={content}
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    components={{
                        a({ href, children, ...props }) {
                            if (href?.startsWith('#')) {
                                return (
                                    <a
                                        href={href}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            const targetId = href.slice(1); // # 제거
                                            
                                            // 먼저 정확한 ID로 요소 찾기
                                            let targetElement = document.getElementById(targetId);
                                            
                                            // ID로 찾지 못한 경우 다른 방법 시도
                                            if (!targetElement) {
                                                // 각주 참조 번호를 찾는 경우
                                                const footnoteRefs = document.getElementsByClassName('footnote-ref');
                                                for (let ref of footnoteRefs) {
                                                    if (ref.id === `fnref${targetId}` || ref.id === targetId) {
                                                        targetElement = ref;
                                                        break;
                                                    }
                                                }
                                                
                                                // 각주 내용을 찾는 경우
                                                if (!targetElement) {
                                                    const footnotes = document.getElementsByClassName('footnote');
                                                    for (let note of footnotes) {
                                                        if (note.id === `fn${targetId}` || note.id === targetId) {
                                                            targetElement = note;
                                                            break;
                                                        }
                                                    }
                                                }
                                            }

                                            if (targetElement) {
                                                targetElement.scrollIntoView({
                                                    behavior: 'smooth',
                                                    block: 'center'
                                                });
                                                // 시각적 피드백을 위한 하이라이트 효과
                                                targetElement.style.backgroundColor = '#9c27b0';
                                                setTimeout(() => {
                                                    targetElement.style.backgroundColor = '';
                                                }, 2000);
                                            }
                                        }}
                                        className="markdown-link"
                                        {...props}
                                    >
                                        {children}
                                    </a>
                                );
                            }
                            return <a 
                                href={href} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="markdown-link"
                                {...props}
                            >{children}</a>;
                        },
                        p: ({ children }) => <p style={{ whiteSpace: 'pre-line', textAlign: 'left' }}>{children}</p>,
                        h1: ({ children }) => <h1 style={{ textAlign: 'left' }}>{children}</h1>,
                        h2: ({ children }) => <h2 style={{ textAlign: 'left' }}>{children}</h2>,
                        h3: ({ children }) => <h3 style={{ textAlign: 'left' }}>{children}</h3>,
                        h4: ({ children }) => <h4 style={{ textAlign: 'left' }}>{children}</h4>,
                        h5: ({ children }) => <h5 style={{ textAlign: 'left' }}>{children}</h5>,
                        h6: ({ children }) => <h6 style={{ textAlign: 'left' }}>{children}</h6>,
                        br: () => <br />,
                        sup: SupComponent,
                    }}
                />
            </div>

            {/* 푸터 */}
            <footer className="footer">
                <p>Copyright ⓒ 2024. 이원희 All rights reserved.<br/>개인정보보호를 위해 특정되는 정보를 표시되지 않습니다.</p>
            </footer>
        </div>
    );
}

export default NamuWikiPage;